import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import styles from './AppLink.module.sass'
import { useAppLinksData } from './contexts/AppLinksContext'
import { Icon } from './Icon'

export type AppLinkProps = {
	type: 'apple' | 'google'
}

export const AppLink: FunctionComponent<AppLinkProps> = ({ type }) => {
	const { appStoreLink, googlePlayLink } = useAppLinksData()
	return (
		<>
			{type === 'apple' && appStoreLink ? (
				<Link
					className={styles.link}
					href={contemberLinkToHref(appStoreLink)}
					// @TODO: own component
					target={appStoreLink.isTargetBlank ? '_blank' : undefined}
				>
					<Icon name="appStore" />
				</Link>
			) : type === 'google' && googlePlayLink ? (
				<Link
					className={styles.link}
					href={contemberLinkToHref(googlePlayLink)}
					// @TODO: own component
					target={googlePlayLink.isTargetBlank ? '_blank' : undefined}
				>
					<Icon name="googlePlay" />
				</Link>
			) : null}
		</>
	)
}
