import type { FunctionComponent } from 'react'
import { ValueTile, ValueTileProps } from './ValueTile'
import styles from './ValueTileList.module.sass'

export type ValueTileListProps = {
	title?: string
	items: ValueTileProps[]
}

export const ValueTileList: FunctionComponent<ValueTileListProps> = ({ title, items }) => {
	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>{title}</h2>
			<div className={styles.list}>
				{items.map((item, count) => (
					<div key={item.id} className={styles.item}>
						<ValueTile {...item} count={count + 1} />
					</div>
				))}
			</div>
		</div>
	)
}
