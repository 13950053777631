import { createContext, FunctionComponent, ReactNode, useContext } from 'react'
import { LinkResult } from '../../data/LinkFragment'

interface AppLinksContextProps {
	appStoreLink?: LinkResult
	googlePlayLink?: LinkResult
}

const defaultValues: AppLinksContextProps = {
	appStoreLink: undefined,
	googlePlayLink: undefined,
}

export const AppLinksContext = createContext(defaultValues)
export const AppLinksContentProvider: FunctionComponent<{ data: AppLinksContextProps; children: ReactNode }> = ({
	data,
	children,
}) => {
	return <AppLinksContext.Provider value={data}>{children}</AppLinksContext.Provider>
}

export const useAppLinksData = () => {
	const getAppLinksData = useContext(AppLinksContext)

	return getAppLinksData
}
