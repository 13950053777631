import clsx from 'clsx'
import Link from 'next/link'
import { FunctionComponent } from 'react'
import { ContentWithLinkResult } from '../data/ContentWithLinkFragment'
import { ImageResult } from '../data/ImageFragment'
import { ImageLinkListResult } from '../data/ImageLinkListFragment'
import { LinkResult } from '../data/LinkFragment'
import { AsideMediaContentLayout, ContentAlignment } from '../generated/content'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import styles from './AsideMediaContent.module.sass'
import { Container } from './Container'
import { MediaContentText, MediaContentTitle } from './MediaContent'
import { ResponsiveImage } from './ResponsiveImage'

export type AsideMediaContentProps = {
	layout?: AsideMediaContentLayout
	alignment?: ContentAlignment
	title?: string
	text?: string
	textContent?: ContentWithLinkResult
	link?: LinkResult
	image?: ImageResult
	socialList?: ImageLinkListResult
	isAsideMediaContentMobileLayout?: boolean
	isTitleBigger?: boolean
}

export const AsideMediaContent: FunctionComponent<AsideMediaContentProps> = ({
	layout,
	alignment,
	title,
	textContent,
	link,
	image,
	socialList,
	isAsideMediaContentMobileLayout = false,
	isTitleBigger = false,
}) => {
	return (
		<div className={styles.wrapper}>
			<Container size={layout === AsideMediaContentLayout.wide ? 'wide' : 'normal'}>
				<div
					className={clsx(
						styles.main,
						styles[`is_layout_${layout}`],
						styles[`is_contentAlignment_${alignment}`],
						isAsideMediaContentMobileLayout && styles.is_isAsideMediaContentMobileLayout,
					)}
				>
					<div className={styles.content}>
						{title && <MediaContentTitle title={title} isTitleBigger={isTitleBigger} />}
						{textContent?.blocks && <MediaContentText textContent={textContent} isTextBigger />}
						{socialList && <SocialIcons socialList={socialList} deviceType="desktop" />}
						{link && (
							<div className={styles.actions}>
								<Link
									href={contemberLinkToHref(link)}
									// @TODO: own component
									target={link.isTargetBlank ? '_blank' : undefined}
									className={styles.link}
								>
									{link.title}
								</Link>
							</div>
						)}
					</div>
					<div className={styles.column}>
						<div className={styles.image}>
							{image && (
								<ResponsiveImage
									src={image.url}
									width={image.width}
									height={image.height}
									alt={image.alt ?? ''}
									sizes="100vw, (min-width: 992px) 600px"
								/>
							)}
						</div>
						{socialList && <SocialIcons socialList={socialList} />}
					</div>
				</div>
			</Container>
		</div>
	)
}

type SocialIconsProps = {
	socialList: ImageLinkListResult
	deviceType?: 'mobile' | 'desktop'
}

const SocialIcons: FunctionComponent<SocialIconsProps> = ({ socialList, deviceType = 'mobile' }) => {
	return (
		<div className={clsx(styles.socials, styles[`is_${deviceType}`])}>
			{socialList.items.map((item) => (
				<div key={item.id} className={styles.socialItem}>
					{item.item?.link ? (
						<>
							<Link
								className={styles.socialItemLink}
								href={contemberLinkToHref(item.item.link)}
								target={item.item.link.isTargetBlank ? '_blank' : undefined}
							>
								{item.item.image && (
									<ResponsiveImage
										className={styles.socialImage}
										src={item.item.image.url}
										width={item.item.image.width}
										height={item.item.image.height}
										alt={item.item.image.alt ?? ''}
									/>
								)}
							</Link>
						</>
					) : null}
				</div>
			))}
		</div>
	)
}
