import { useEffect } from 'react'
import { useIntersection } from 'react-use'
import { useReducedMotion } from './useReducedMotion'

export const useAutoplayVideo = (videoElement: HTMLVideoElement | null, isInViewAutoplay = false) => {
	const reducedMotion = useReducedMotion()

	const intersection = useIntersection(
		{ current: videoElement },
		{
			threshold: 0.5,
		},
	)

	useEffect(() => {
		if (videoElement === null) {
			return
		}
		if (reducedMotion) {
			videoElement.pause()
		} else {
			if (isInViewAutoplay) {
				intersection?.isIntersecting && videoElement.play()
			} else {
				videoElement.pause()
			}
		}
	}, [intersection?.isIntersecting, isInViewAutoplay, reducedMotion, videoElement])
}
