import { FunctionComponent, useCallback } from 'react'
import { ValueListItemResult } from '../data/ValueListItemFragment'
import { BreakableContent } from './BreakableContent'
import { ContentRendererWithInlineLink } from './ContentRenderer'
import styles from './ValueTile.module.sass'

export type ValueTileProps = ValueListItemResult & {
	count?: number
}

export const ValueTile: FunctionComponent<ValueTileProps> = ({ count, item }) => {
	const zedoPad = useCallback((number: number) => String(number).padStart(2, '0'), [])
	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				{count && (
					<div className={styles.number}>
						<span className={styles.is_desktop}>{zedoPad(count)}</span>
						<span className={styles.is_mobile}>{count}</span>
					</div>
				)}
				<h3 className={styles.title}>{item?.title && <BreakableContent text={item?.title} />}</h3>
			</div>
			{item?.textContent && (
				<div className={styles.text}>
					<ContentRendererWithInlineLink content={item.textContent} />
				</div>
			)}
		</div>
	)
}
