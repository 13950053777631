import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { MediumItemResult } from '../data/MediumItemFragment'
import { MediumType } from '../generated/content'
import styles from './ContentMediaGalleryItem.module.sass'
import { Video } from './Video'

export type ContentMediaGalleryItemProps = MediumItemResult

export const ContentMediaGalleryItem: FunctionComponent<ContentMediaGalleryItemProps> = ({ medium }) => {
	if (!medium) {
		return null
	}
	return (
		<div className={styles.wrapper}>
			{medium.type === MediumType.image && medium.image ? (
				<div
					className={styles.medium}
					style={{
						'--ContentMediaGalleryItem-medium-width': medium.image.width,
						'--ContentMediaGalleryItem-medium-height': medium.image.height,
					}}
				>
					<Image src={medium.image.url} fill alt={medium.image.alt ?? ''} />
				</div>
			) : medium.type === MediumType.video && medium.video ? (
				<div
					className={styles.medium}
					style={{
						'--ContentMediaGalleryItem-medium-width': medium.video.width,
						'--ContentMediaGalleryItem-medium-height': medium.video.height,
					}}
				>
					<Video {...medium.video} />
				</div>
			) : null}
		</div>
	)
}
