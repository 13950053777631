import { FunctionComponent, useState } from 'react'
import { AccordionResult } from '../data/AccordionFragment'
import styles from './Accordion.module.sass'
import { AccordionItem } from './AccordionItem'

export type AccordionProps = AccordionResult

export const Accordion: FunctionComponent<AccordionProps> = ({ items }) => {
	const [isItemOpenId, setItemOpenId] = useState<AccordionResult['items'][number]['id'] | null>(items[0].id)
	return (
		<div className={styles.wrapper}>
			<div className={styles.list}>
				{items.map((item) => (
					<div key={item.id} className={styles.item}>
						<AccordionItem {...item} isItemOpenId={isItemOpenId} setItemOpenId={setItemOpenId} />
					</div>
				))}
			</div>
		</div>
	)
}
