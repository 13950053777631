import type { FunctionComponent } from 'react'
import { FormResult } from '../data/FormFragment'
import { FormType } from '../generated/content'
import { TaxForm } from './TaxForm'

export type FormProps = FormResult

export const Form: FunctionComponent<FormProps> = ({ type }) => {
	if (type === FormType.tax) {
		return <TaxForm />
	} // add other form types here

	return <></>
}
