import type { FunctionComponent } from 'react'
import styles from './ContentMediaGallery.module.sass'
import { ContentMediaGalleryItem, ContentMediaGalleryItemProps } from './ContentMediaGalleryItem'

export type ContentMediaGalleryProps = {
	list: ContentMediaGalleryItemProps[]
}

export const ContentMediaGallery: FunctionComponent<ContentMediaGalleryProps> = ({ list }) => {
	return (
		<div className={styles.list}>
			{list.map(
				(item) =>
					item && (
						<div key={item.id} className={styles.item}>
							<ContentMediaGalleryItem {...item} />
						</div>
					),
			)}
		</div>
	)
}
