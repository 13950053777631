import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Link from 'next/link'
import { FunctionComponent, ReactNode, useMemo } from 'react'
import { ContentBlockResult } from '../data/ContentBlockFragment'
import { ContentBlockReferencesBaseResult } from '../data/ContentBlockReferencesBaseFragment'
import { ContentResult } from '../data/ContentFragment'
import { ContentWithLinkResult } from '../data/ContentWithLinkFragment'
import { ContentReferenceType } from '../generated/content'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import {
	useContentRendererCopyPasteBugWorkaround,
	useContentRendererWithLinkCopyPasteBugWorkaround,
} from '../utilities/useContentRendererCopyPasteBugWorkaround'
import { Accordion } from './Accordion'
import { AppBanner, AppBannerProps } from './AppBanner'
import { AreaListBlock } from './AreaListBlock'
import { AsideMediaContent } from './AsideMediaContent'
import { Button } from './Button'
import { Container, ContainerProps } from './Container'
import { ContentMedia } from './ContentMedia'
import styles from './ContentRenderer.module.sass'
import { Form } from './Form'
import { GridImages } from './GridImages'
import { HideContentBelow } from './HideContentBelow'
import { Linkables } from './Linkables'
import { MemberList } from './MemberList'
import { ResponsiveImage } from './ResponsiveImage'
import { ValueTileList } from './ValueTileList'
import { Video } from './Video'
import { Wysiwyg } from './Wysiwyg'

export interface ContentRendererProps {
	content: ContentResult
	containerSize?: ContainerProps['size']
	containerDisableGutters?: boolean
	postAppBanner?: AppBannerProps
}

type Block = ReferenceRendererProps<ContentBlockResult['references'][number]>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem', 'anchor', 'tableCell', 'tableRow', 'scrollTarget', 'link']

const createReferenceRenderers = (
	banner?: ContentRendererProps['postAppBanner'],
): {
	[referenceType in ContentReferenceType]?: (block: Block) => ReactNode
} => {
	return {
		accordion: function accordion({ reference }) {
			return reference.accordion && <Accordion items={reference.accordion.items} />
		},
		appBanner: function appBanner() {
			return banner && <AppBanner {...banner} />
		},
		areaList: function areaList({ reference }) {
			return reference.areaList && <AreaListBlock title={reference.primaryText} items={reference.areaList.items} />
		},
		asideMediaContent: function asideMediaContent({ reference }) {
			return (
				<AsideMediaContent
					alignment={reference.contentAlignment}
					layout={reference.asideMediaContentLayout}
					title={reference.primaryText}
					textContent={reference.contentWithoutBlocks}
					image={reference.image}
					link={reference.link}
					socialList={reference.imageLinkList}
					isAsideMediaContentMobileLayout={reference.isAsideMediaContentMobileLayout}
					isTitleBigger={reference.isTitleBigger}
				/>
			)
		},
		button: function button({ reference }) {
			return (
				reference.link && (
					<Button type="link" href={contemberLinkToHref(reference.link)}>
						{reference.link.title}
					</Button>
				)
			)
		},
		contentMedia: function contentMedia({ reference }) {
			return (
				<ContentMedia
					title={reference.primaryText}
					textContent={reference.contentWithoutBlocks}
					media={reference.media}
					image={reference.image}
					isAppsVisible={reference.isAppsVisible}
				/>
			)
		},
		gridImages: function gridImages({ reference }) {
			return reference.imageLinkList && <GridImages items={reference.imageLinkList.items} />
		},
		hideContentBelow: function hideContentBelow() {
			return <HideContentBelow />
		},
		image: function image({ reference }) {
			return (
				reference.image && (
					<ResponsiveImage
						src={reference.image.url}
						width={reference.image.width}
						height={reference.image.height}
						alt={reference.image.alt ?? ''}
					/>
				)
			)
		},
		link: function link({ reference }) {
			return reference.link && <Link {...contemberLinkToHrefTargetRel(reference.link)}>{reference.link.title}</Link>
		},
		members: function members({ reference }) {
			return reference.memberList && <MemberList items={reference.memberList.items} />
		},
		form: function form({ reference }) {
			return reference.form && <Form {...reference.form} />
		},
		values: function values({ reference }) {
			return reference.valueList && <ValueTileList title={reference.primaryText} items={reference.valueList.items} />
		},
		video: function video({ reference }) {
			return reference.video && <Video {...reference.video} />
		},
		linkables: function linkables({ reference }) {
			return <Linkables title={reference.primaryText} items={reference.linkables} />
		},
	}
}

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({
	content,
	containerSize = 'normal',
	containerDisableGutters = false,
	postAppBanner,
}) => {
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)
	const referenceRenderers = useMemo(() => createReferenceRenderers(postAppBanner), [postAppBanner])

	return useMemo(
		() => (
			<div className={styles.wrapper}>
				<RichTextRenderer
					blocks={blocks}
					sourceField="json"
					renderElement={(element) => {
						const type = element.element.type

						if (type === 'table') {
							return (
								<div className={clsx(styles.section, styles[`is_reference_${type}`])}>
									<Container disableGutters={containerDisableGutters}>{element.fallback}</Container>
								</div>
							)
						}

						if (nestedTypes.includes(type)) {
							const reference = element.reference as ContentBlockReferencesBaseResult // @TODO: remove cast
							if (element.referenceType === 'link' && reference.link) {
								return <Link {...contemberLinkToHrefTargetRel(reference.link)}>{element.children}</Link>
							}
							return element.fallback
						}

						if (standaloneTypes.includes(type)) {
							return (
								<div
									className={clsx(
										styles.section,
										element.referenceType && styles[`is_reference_${element.referenceType}`],
									)}
								>
									{type !== 'reference' || !element.referenceType || element.referenceType in referenceRenderers ? (
										<Container
											// the width in "AsideMediaContent" block is handled inside component
											size={element.referenceType === 'asideMediaContent' ? 'fullWidth' : containerSize}
											disableGutters={element.referenceType === 'asideMediaContent' ? true : containerDisableGutters}
										>
											{element.fallback}
										</Container>
									) : (
										<Container disableGutters={containerDisableGutters}>
											<div className={styles.notImplemented}>
												<div className={styles.notImplemented_name}>{element.referenceType}</div>
												is not yet implemented
											</div>
										</Container>
									)}
								</div>
							)
						}
						return (
							<div className={clsx(styles.section, styles.is_wysiwyg)}>
								<Container disableGutters={containerDisableGutters} size={containerSize}>
									<Wysiwyg>{element.fallback}</Wysiwyg>
								</Container>
							</div>
						)
					}}
					referenceRenderers={referenceRenderers}
				/>
			</div>
		),
		[blocks, containerDisableGutters, containerSize, referenceRenderers],
	)
}

type ContentRendererWithInlineLinkProps = {
	content: ContentWithLinkResult
}

export const ContentRendererWithInlineLink: FunctionComponent<ContentRendererWithInlineLinkProps> = ({ content }) => {
	const blocks = useContentRendererWithLinkCopyPasteBugWorkaround(content.blocks)
	const nestedTypes = useMemo(() => ['listItem', 'anchor', 'tableCell', 'tableRow', 'scrollTarget', 'link'], [])

	return useMemo(
		() => (
			<div className={clsx(styles.wrapper, styles.is_withInlineLink)}>
				<RichTextRenderer
					blocks={blocks}
					sourceField="json"
					renderElement={(element) => {
						const type = element.element.type

						if (nestedTypes.includes(type)) {
							const reference = element.reference as ContentBlockReferencesBaseResult // @TODO: remove cast
							if (element.referenceType === 'link' && reference.link) {
								return <Link {...contemberLinkToHrefTargetRel(reference.link)}>{element.children}</Link>
							}
							return element.fallback
						}
						return <div className={styles.is_wysiwyg}>{element.fallback}</div>
					}}
				/>
			</div>
		),
		[blocks, nestedTypes],
	)
}
