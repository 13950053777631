import type { FunctionComponent } from 'react'
import { AppBannerLinks } from './AppBanner'
import styles from './HideContentBelow.module.sass'
import { useTranslate } from './contexts/TranslationsContextProvider'

export const HideContentBelow: FunctionComponent = () => {
	const translations = useTranslate()
	return (
		<div className={styles.wrapper}>
			<div className={styles.gradient} />
			<div className={styles.content}>
				{translations('hideContentBelow')}
				<div className={styles.appBanner}>
					<AppBannerLinks />
				</div>
			</div>
		</div>
	)
}
