import type { FunctionComponent } from 'react'
import styles from './AreaListBlock.module.sass'
import { AreaTile, AreaTileProps } from './AreaTile'

export type AreaListBlockProps = {
	title?: string
	items: AreaTileProps[]
}

export const AreaListBlock: FunctionComponent<AreaListBlockProps> = ({ title, items }) => {
	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>{title}</h2>
			<ul className={styles.list}>
				{items.map((item) => (
					<li key={item.id} className={styles.item}>
						<AreaTile {...item} />
					</li>
				))}
			</ul>
		</div>
	)
}
