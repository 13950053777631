import clsx from 'clsx'
import { FunctionComponent } from 'react'
import { ContentWithLinkResult } from '../data/ContentWithLinkFragment'
import { ContentRendererWithInlineLink } from './ContentRenderer'
import { useLocales } from './contexts/SwitcherLocalesContext'
import styles from './MediaContent.module.sass'

export type MediaContentTitleProps = {
	title: string
	isTitleBigger?: boolean
}

export const MediaContentTitle: FunctionComponent<MediaContentTitleProps> = ({ title, isTitleBigger = false }) => {
	const locales = useLocales()
	return (
		<h2
			className={clsx(
				styles.title,
				isTitleBigger && styles.is_bigger,
				locales?.currentLocaleCode === 'cs' && styles.is_biggerLineHeight,
			)}
		>
			{title}
		</h2>
	)
}

export type MediaContentTextProps = {
	textContent: ContentWithLinkResult
	isTextBigger?: boolean
}

export const MediaContentText: FunctionComponent<MediaContentTextProps> = ({ textContent, isTextBigger = false }) => {
	return (
		<div className={clsx(styles.text, isTextBigger && styles.is_bigger)}>
			<ContentRendererWithInlineLink content={textContent} />
		</div>
	)
}
