import Image from 'next/image'
import { useRouter } from 'next/router'
import { FunctionComponent, useId } from 'react'
import ReactSelect from 'react-select'
import styles from './SelectLocales.module.sass'
import { useLocales } from './contexts/SwitcherLocalesContext'

export const SelectLocales: FunctionComponent = () => {
	const locales = useLocales()
	const router = useRouter()

	const options = locales?.pageLocales?.map((item) => ({
		value: item.locale?.code,
		label: (
			<div className={styles.localeLabel}>
				{item.locale?.flagImage && (
					<Image
						className={styles.flagImage}
						src={item.locale.flagImage.url}
						width={item.locale.flagImage.width}
						height={item.locale.flagImage.height}
						alt={item.locale.flagImage.alt ?? item.locale.flagImage.fileName ?? ''}
					/>
				)}
				{item.locale?.code}
			</div>
		),
		flagImage: item.locale?.flagImage,
		link: item.link?.url ?? `/${item.locale?.code}`,
	}))

	const instanceId = useId()

	if (!options || options.length <= 1) {
		return null
	}

	return (
		<ReactSelect
			// need to pass id to instanceId to avoid warning (see: https://stackoverflow.com/questions/61290173/react-select-how-do-i-resolve-warning-prop-id-did-not-match)
			instanceId={instanceId}
			options={options}
			className={styles.select}
			classNamePrefix="select"
			isSearchable={false}
			value={options?.find((option) => option?.value === locales?.currentLocaleCode)}
			onChange={(option) => {
				if (!option) {
					return
				}
				router.push(option.link, undefined, { locale: option.value })
			}}
			menuPlacement="auto"
		/>
	)
}
