import { FunctionComponent, InputHTMLAttributes } from 'react'
import { useFormContext, ValidationRule } from 'react-hook-form'
import { useTranslate } from './contexts/TranslationsContextProvider'
import styles from './Input.module.sass'
import { LabelWithNonDefaultFieldProps } from './LabelWithField'

export type InputProps = {
	type?: InputHTMLAttributes<HTMLInputElement>['type']
	autoComplete?: InputHTMLAttributes<HTMLInputElement>['autoComplete']
} & LabelWithNonDefaultFieldProps

export const Input: FunctionComponent<InputProps> = ({
	name,
	type = 'text',
	autoComplete,
	required,
	onFocus,
	onBlur,
}) => {
	const translations = useTranslate()
	const { register } = useFormContext()

	const pattern: ValidationRule<RegExp> | undefined =
		type === 'email'
			? {
					value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
					message: translations('Form.field.invalidEmail'),
			  }
			: undefined

	return (
		<input
			className={styles.wrapper}
			type={type}
			autoComplete={autoComplete}
			onFocus={onFocus}
			{...register(name, {
				required: required && translations('Form.field.required'),
				pattern: pattern,
				onBlur: onBlur,
			})}
		/>
	)
}
