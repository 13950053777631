import clsx from 'clsx'
import Link from 'next/link'
import { FunctionComponent, useState } from 'react'
import { PageProps } from '../pages/[[...path]]'
import { Container } from './Container'
import styles from './Header.module.sass'
import { HeaderNavigation } from './HeaderNavigation'
import { ResponsiveImage } from './ResponsiveImage'

export type HeaderProps = Pick<PageProps, 'header'>

export const Header: FunctionComponent<HeaderProps> = ({ header }) => {
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

	// const [platform, setPlatform] = useState<string | null>(null)

	// useEffect(() => {
	// 	setPlatform(window.navigator.userAgentData.platform ?? null)
	// }, [])

	// console.log(platform)

	return (
		<div className={clsx(styles.wrapper, isMobileMenuOpen && styles.is_mobileOpen)}>
			<Container size="fullWidth">
				<div className={styles.main}>
					<div className={styles.logo}>
						{header?.logo && (
							<Link title="logo" href="/" className={styles.logoLink}>
								<ResponsiveImage
									src={header.logo.url}
									width={header.logo.width}
									height={header.logo.height}
									alt={header.logo.alt ?? ''}
									sizes="200px"
								/>
							</Link>
						)}
					</div>
					<div className={styles.navigation}>
						<div className={styles.is_desktop}>
							<HeaderNavigation type="desktop" links={header?.localesByLocale?.links} />
						</div>
						<div className={styles.is_mobile}>
							<button
								type="button"
								aria-label="hamburger"
								className={clsx(styles.hamburger)}
								onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
							>
								<div className={styles.lines}>
									<div className={styles.line} />
									<div className={styles.line} />
									<div className={styles.line} />
								</div>
							</button>
							<div className={styles.navigationMobile}>
								<HeaderNavigation
									type="mobile"
									links={header?.localesByLocale?.links}
									setMobileMenuOpen={setMobileMenuOpen}
								/>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}
