import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { PostAreaListItemResult } from '../data/PostAreaListItemFragment'
import styles from './AreaTile.module.sass'

export type AreaTileProps = PostAreaListItemResult

export const AreaTile: FunctionComponent<AreaTileProps> = ({ item }) => {
	const icon = item?.icon

	return (
		<div className={styles.wrapper}>
			{item?.localesByLocale?.link && (
				<Link href={item.localesByLocale.link.url} className={styles.link}>
					{icon?.url && (
						<Image
							className={styles.icon}
							src={icon.url}
							width={icon.width}
							height={icon.height}
							alt={icon.alt ?? ''}
							sizes="90px"
						/>
					)}
					<h4 className={styles.name}>{item?.localesByLocale?.name}</h4>
				</Link>
			)}
		</div>
	)
}
