import { FunctionComponent } from 'react'
import { MemberListResult } from '../data/MemberListFragment'
import { ContentRendererWithInlineLink } from './ContentRenderer'
import styles from './MemberList.module.sass'
import { ResponsiveImage } from './ResponsiveImage'

export type MemberListProps = {
	items: MemberListResult['items']
}

export const MemberList: FunctionComponent<MemberListProps> = ({ items }) => {
	return (
		<ul className={styles.wrapper}>
			{items.map((item) => {
				return (
					<li key={item.id} className={styles.item}>
						<div className={styles.imageWrapper}>
							{item.image && (
								<ResponsiveImage
									className={styles.image}
									src={item.image.url}
									width={item.image.width}
									height={item.image.height}
									alt={item.image.alt ?? ''}
								/>
							)}
						</div>
						<div className={styles.content}>
							<h3 className={styles.name}>{item.name}</h3>
							<p className={styles.position}>{item.position}</p>
							{item.descriptionContent && <ContentRendererWithInlineLink content={item.descriptionContent} />}
						</div>
					</li>
				)
			})}
		</ul>
	)
}
