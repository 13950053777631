import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import styles from './FlashMessage.module.sass'

export type FlashMessageProps = {
	type: 'success' | 'error'
	message: string
}

export const FlashMessage: FunctionComponent<FlashMessageProps> = ({ type, message }) => {
	return (
		<div className={clsx(styles.wrapper, styles[`is_${type}`])}>
			<p className={styles.message}>{message}</p>
		</div>
	)
}
