import { FunctionComponent, useId } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Select, { Props } from 'react-select'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type SelectOptionProps = {
	value: string
	label: string | undefined
}

export interface FormSelectProps
	extends Pick<Props, 'onFocus' | 'onBlur' | 'menuIsOpen' | 'isSearchable' | 'className'> {
	name: string
	options: SelectOptionProps[]
	required?: boolean
}

export const FormSelect: FunctionComponent<FormSelectProps> = ({
	name,
	onFocus,
	onBlur,
	menuIsOpen,
	isSearchable = false,
	options,
	className,
	required,
}) => {
	const { control } = useFormContext()
	const translations = useTranslate()
	const instanceId = useId()

	return (
		<Controller
			name={name}
			control={control}
			rules={{ required: required && translations('Form.field.required') }}
			render={({ field: { value, onChange } }) => {
				const option = options.find((otherOption) => otherOption.value === value)
				return (
					<Select
						// need to pass id to instanceId to avoid warning (see: https://stackoverflow.com/questions/61290173/react-select-how-do-i-resolve-warning-prop-id-did-not-match)
						instanceId={instanceId}
						name={name}
						className={className}
						options={options}
						value={option}
						classNamePrefix="select"
						onChange={(option) => {
							onChange(option?.value)
						}}
						isClearable
						onFocus={onFocus}
						onBlur={onBlur}
						menuIsOpen={menuIsOpen}
						isSearchable={isSearchable}
						placeholder={translations('FormSelect.placeholder')}
					/>
				)
			}}
		/>
	)
}
