export const appButtonsVisibilityByPlatformType = {
	ios: {
		appStore: true,
		googlePlay: false,
	} as const,
	mac: {
		appStore: true,
		googlePlay: true,
	} as const,
	android: {
		appStore: false,
		googlePlay: true,
	} as const,
	windows: {
		appStore: true,
		googlePlay: true,
	} as const,
	unknown: {
		appStore: true,
		googlePlay: true,
	} as const,
} as const
