import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { usePlatform } from 'react-use-platform'
import { AppBannerResult } from '../data/AppBannerFragment'
import { ImageResult } from '../data/ImageFragment'
import { VideoResult } from '../data/VideoFragment'
import { appButtonsVisibilityByPlatformType } from '../utilities/appButtonsVisibilityByPlatformType'
import { useAutoplayVideo } from '../utilities/useAutoplayVideo'
import styles from './AppBanner.module.sass'
import { AppLink } from './AppLink'
import { BreakableContent } from './BreakableContent'
import { ContentRendererWithInlineLink } from './ContentRenderer'
import { ResponsiveImage } from './ResponsiveImage'

export type AppBannerProps = {
	color?: string
	direction: 'vertical' | 'horizontal'
} & (AppBannerDefaultProps | AppBannerVideoProps)

type AppBannerDefaultProps = {
	type: 'default'
} & AppBannerResult

type AppBannerVideoProps = {
	type: 'video'
	tileVideo?: VideoResult
	tileImage?: ImageResult
}

export const AppBanner: FunctionComponent<AppBannerProps> = ({ direction, color, ...props }) => {
	const type = props.type

	const [videoRef, setVideoRef] = useState<null | HTMLVideoElement>(null)

	useAutoplayVideo(videoRef, true)
	return (
		// @TODO: color text contrast to background
		<div
			className={clsx(styles.wrapper, styles[`is_${type}`], styles[`is_${direction}`])}
			style={{ '--AppBanner-color': color }}
		>
			{type === 'default' ? (
				<div className={styles.content}>
					<h3 className={styles.title}>{props.title && <BreakableContent text={props.title} />}</h3>
					<div className={styles.contentIn}>
						{props.textContent && (
							<div className={styles.text}>
								<ContentRendererWithInlineLink content={props.textContent} />
							</div>
						)}
						<AppBannerLinks />
					</div>
					<div className={styles.image}>
						{props.imageSmall && direction === 'vertical' ? (
							<ResponsiveImage
								className={styles.image}
								src={props.imageSmall.url}
								width={props.imageSmall.width}
								height={props.imageSmall.height}
								alt={props.imageSmall.alt ?? ''}
								sizes="120px"
							/>
						) : props.imageBig && direction === 'horizontal' ? (
							<ResponsiveImage
								className={styles.image}
								src={props.imageBig.url}
								width={props.imageBig.width}
								height={props.imageBig.height}
								alt={props.imageBig.alt ?? ''}
								sizes="120px"
							/>
						) : null}
					</div>
				</div>
			) : type === 'video' ? (
				<div className={styles.videoWrapper}>
					<div className={styles.videoMain}>
						{props.tileVideo ? (
							<>
								{props.tileImage && (
									<Image className={styles.tileImage} src={props.tileImage.url} alt={props.tileImage.alt ?? ''} fill />
								)}
								<video
									ref={setVideoRef}
									key={props.tileVideo.url}
									className={styles.videoIn}
									placeholder={props.tileImage?.url}
									playsInline
									loop
									muted
									preload="none"
								>
									<source src={props.tileVideo.url} type={props.tileVideo.type} />
								</video>
							</>
						) : props.tileImage ? (
							<Image className={styles.tileImage} src={props.tileImage.url} alt={props.tileImage.alt ?? ''} fill />
						) : null}
					</div>
					<div className={styles.appButtons}>
						<AppBannerLinks />
					</div>
				</div>
			) : null}
		</div>
	)
}

export const AppBannerLinks: FunctionComponent = () => {
	const platform = usePlatform()

	const refLink = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (!refLink.current) {
			return
		}

		// safari < 15 has a bug where the app store button is display twice somehow
		refLink.current.style.display = 'none'
		refLink.current.offsetHeight // force reflow
		refLink.current.style.display = ''
	}, [platform])
	return (
		<div className={styles.links}>
			{appButtonsVisibilityByPlatformType[platform]['appStore'] && (
				<div className={styles.link} ref={refLink}>
					<AppLink type="apple" />
				</div>
			)}
			{appButtonsVisibilityByPlatformType[platform]['googlePlay'] && (
				<div className={styles.link}>
					<AppLink type="google" />
				</div>
			)}
		</div>
	)
}
