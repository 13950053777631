import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { LinkResult } from '../data/LinkFragment'
import { contemberLinkToHref } from './contemberLinkToHref'

export const useIsCurrentLink = () => {
	const { asPath, defaultLocale, locale } = useRouter()

	const asPathWithLocale = defaultLocale === locale ? asPath : `/${locale}${asPath}`

	return useCallback((link: LinkResult) => asPathWithLocale === contemberLinkToHref(link), [asPathWithLocale])
}
