import { createContext, FunctionComponent, ReactNode, useContext } from 'react'
import { RegionResult } from '../../data/RegionFragment'

type RegionListContextProps = RegionResult[]

const defaultValues: RegionListContextProps = [
	{
		id: '',
		code: '',
		label: '',
	},
]

export const RegionListContext = createContext(defaultValues)
export const RegionListContentProvider: FunctionComponent<{ data: RegionListContextProps; children: ReactNode }> = ({
	data,
	children,
}) => {
	return <RegionListContext.Provider value={data}>{children}</RegionListContext.Provider>
}

export const useRegionListData = () => {
	const getRegionListData = useContext(RegionListContext)

	return getRegionListData
}
