import clsx from 'clsx'
import { FunctionComponent, InputHTMLAttributes, useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormDateInputProps, RangeDatePicker } from './FormRangeDatePicker'
import { FormSelect, FormSelectProps } from './FormSelect'
import { Input, InputProps } from './Input'
import styles from './LabelWithField.module.sass'

export type LabelWithNonDefaultFieldProps = Pick<
	InputHTMLAttributes<HTMLInputElement>,
	'required' | 'onFocus' | 'onBlur'
> & {
	name: NonNullable<InputHTMLAttributes<HTMLInputElement>['name']>
}

export type LabelWithFieldProps = (LabelWithInputProps | LabelWithInputRangeDateProps | LabelWithInputSelectProps) & {
	labelText?: string
} & LabelWithNonDefaultFieldProps

export type LabelWithInputProps = {
	fieldType: 'default'
	autoComplete?: InputHTMLAttributes<HTMLInputElement>['autoComplete']
} & InputProps

export type LabelWithInputRangeDateProps = {
	fieldType: 'rangeDate'
} & FormDateInputProps

export type LabelWithInputSelectProps = {
	fieldType: 'select'
} & FormSelectProps

export const LabelWithField: FunctionComponent<LabelWithFieldProps> = (props) => {
	const [isFocused, setFocused] = useState(false)

	const onFocus = useCallback(() => {
		setFocused(true)
	}, [])

	const onBlur = useCallback(() => {
		setFocused(false)
	}, [])

	const {
		formState: { errors },
	} = useFormContext()

	return (
		<div className={clsx(styles.wrapper, styles[`is_${props.fieldType}`], isFocused && styles.is_focused)}>
			<label className={styles.label}>
				<div className={styles.labelText}>{props.labelText}</div>
				{props.fieldType === 'default' ? (
					<Input {...props} onFocus={onFocus} onBlur={onBlur} />
				) : props.fieldType === 'rangeDate' ? (
					<RangeDatePicker {...props} onFocus={onFocus} onBlur={onBlur} className={styles.datePickerInput} />
				) : props.fieldType === 'select' ? (
					<FormSelect {...props} onFocus={onFocus} onBlur={onBlur} className={styles.select} />
				) : null}
			</label>
			{errors[props.name] && (
				<div className={styles.errors}>
					<p className={styles.alert} role="alert">
						{errors[props.name]?.message?.toString()}
					</p>
				</div>
			)}
		</div>
	)
}
