import clsx from 'clsx'
import { ButtonBase, DistinctBaseButtonProps, SharedBaseButtonProps } from 'nextjs-button-base'
import { FunctionComponent } from 'react'
import styles from './Button.module.sass'

export type ButtonProps = Omit<SharedBaseButtonProps, 'className'> &
	DistinctBaseButtonProps & {
		size?: 'default' | 'small'
		variant?: 'primary' | 'secondary'
	}

export const Button: FunctionComponent<ButtonProps> = ({
	children,
	variant = 'primary',
	size = 'default',
	...otherProps
}) => {
	return (
		<ButtonBase
			className={clsx(styles.wrapper, styles[`is_size_${size}`], styles[`is_variant_${variant}`])}
			{...otherProps}
		>
			{children}
		</ButtonBase>
	)
}
