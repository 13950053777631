import type { FunctionComponent } from 'react'
import { ContentReferenceLinkableItemResult } from '../data/ContentReferenceLinkableItemFragment'
import styles from './Linkables.module.sass'

export interface LinkablesProps {
	title?: string
	items: ContentReferenceLinkableItemResult[]
}

export const Linkables: FunctionComponent<LinkablesProps> = ({ title, items }) => {
	return (
		<div className={styles.wrapper}>
			{title && <h3>{title}</h3>}
			<ul>
				{items.map(({ id, item }) => (
					<li key={id}>
						<a href={item?.url}>{item?.url}</a>
					</li>
				))}
			</ul>
		</div>
	)
}
