import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { FunctionComponent } from 'react'
import { PageProps } from '../pages/[[...path]]'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { BreakableContent } from './BreakableContent'
import { Container } from './Container'
import { ContentRendererWithInlineLink } from './ContentRenderer'
import styles from './Footer.module.sass'
import { SelectLocales } from './SelectLocales'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type FooterProps = Pick<PageProps, 'footer'>

export const Footer: FunctionComponent<FooterProps> = ({ footer }) => {
	const translations = useTranslate()

	return (
		<div className={styles.wrapper}>
			<Container size="wide">
				<div className={styles.main}>
					<Container disableGutters>
						<div className={styles.mainIn}>
							<div className={styles.column}>
								{footer?.logo && (
									<div
										className={styles.logo}
										style={{
											'--Footer-logo-width': footer.logo.width,
											'--Footer-logo-height': footer.logo.height,
										}}
									>
										<Image
											className={styles.logoIn}
											src={footer.logo.url}
											alt={footer.logo.alt ?? footer.logo.fileName ?? ''}
											fill
										/>
									</div>
								)}
								<div className={clsx(styles.locales, styles.is_desktop)}>
									<SelectLocales />
								</div>
							</div>
							<div className={styles.column}>
								<ul className={styles.linkList}>
									{footer?.localesByLocale?.linkList?.items &&
										footer.localesByLocale.linkList.items.length > 0 &&
										footer.localesByLocale.linkList.items.map(
											(item) =>
												item.link && (
													<li key={item.id} className={styles.linkItem}>
														<Link
															className={styles.linkItemLink}
															href={contemberLinkToHref(item.link)}
															// @TODO: own component
															target={item.link.isTargetBlank ? '_blank' : undefined}
														>
															{item.link.title}
														</Link>
													</li>
												),
										)}
									{/* <li className={styles.linkItem}>
										<button
											type="button"
											className={styles.linkItemLink}
											onClick={() => {
												forceOpenCookieBar()
											}}
										>
											{translations('footer.cookiesButton')}
										</button>
									</li> */}
								</ul>
							</div>
							<div className={styles.column}>
								{footer?.localesByLocale && (
									<>
										{footer.localesByLocale.copyright && <BreakableContent text={footer.localesByLocale.copyright} />}
										{footer.localesByLocale.addressInfo && (
											<address className={styles.addressInfo}>
												<ContentRendererWithInlineLink content={footer.localesByLocale.addressInfo} />
											</address>
										)}
										{footer.localesByLocale.contactEmail && (
											<div className={styles.contact}>
												<span>{translations('footer.contactEmail')} </span>
												<a className={styles.contactLink} href={`mailto:${footer.localesByLocale.contactEmail}`}>
													{footer.localesByLocale.contactEmail}
												</a>
											</div>
										)}
									</>
								)}
							</div>
							<div className={clsx(styles.locales, styles.is_mobile)}>
								<SelectLocales />
							</div>
						</div>
					</Container>
				</div>
			</Container>
		</div>
	)
}
