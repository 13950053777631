import { useMemo } from 'react';
import type { ContentResult } from '../data/ContentFragment';
import { ContentWithLinkResult } from '../data/ContentWithLinkFragment';

export const useContentRendererCopyPasteBugWorkaround = (blocks: ContentResult['blocks']) =>
	useMemo(() => {
		const allReferences: ContentResult['blocks'][number]['references'] = []

		return blocks.map((block) => {
			allReferences.push(...block.references)

			return { ...block, references: allReferences }
		})
	}, [blocks])


// @TODO: unite with useContentRendererCopyPasteBugWorkaround
export const useContentRendererWithLinkCopyPasteBugWorkaround = (blocks: ContentWithLinkResult['blocks']) =>
	useMemo(() => {
		const allReferences: ContentWithLinkResult['blocks'][number]['references'] = []

		return blocks.map((block) => {
			allReferences.push(...block.references)

			return { ...block, references: allReferences }
		})
	}, [blocks])
