import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { ImageLinkListResult } from '../data/ImageLinkListFragment'
import styles from './GridImages.module.sass'

export type GridImagesProps = ImageLinkListResult

export const GridImages: FunctionComponent<GridImagesProps> = ({ items }) => {
	return (
		<div className={styles.wrapper}>
			{items.map(
				(item) =>
					item.item && (
						<div key={item.id} className={styles.item}>
							{item.item.image && (
								<Image
									className={styles.image}
									src={item.item.image.url}
									width={item.item.image.width}
									height={item.item.image.height}
									alt={item.item.image.alt ?? item.item.image.fileName ?? ''}
								/>
							)}
						</div>
					),
			)}
		</div>
	)
}
