import { FunctionComponent } from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslate } from './contexts/TranslationsContextProvider'

export interface FormDateInputProps
	extends Pick<ReactDatePickerProps<'rangeDatePicker', true>, 'onFocus' | 'onBlur' | 'className'> {
	name: string
	required?: boolean
}

export const RangeDatePicker: FunctionComponent<FormDateInputProps> = ({
	name,
	required,
	className,
	onBlur,
	onFocus,
}) => {
	const { control } = useFormContext()
	const translations = useTranslate()

	return (
		<Controller
			name={name}
			control={control}
			rules={{ required: required && translations('Form.field.required') }}
			render={({ field: { value, onChange } }) => {
				const [startDate, endDate] = value ?? [null, null]
				return (
					<DatePicker
						name={name}
						startDate={startDate}
						endDate={endDate}
						onChange={(value) => {
							onChange(value)
						}}
						selectsRange
						dateFormat="dd. MM. yyyy"
						className={className}
						onBlur={onBlur}
						onFocus={onFocus}
						autoComplete="off"
					/>
				)
			}}
		/>
	)
}
