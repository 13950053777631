import clsx from 'clsx'
import { Collapsible } from 'collapsible-react-component'
import type { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { AccordionItemResult } from '../data/AccordionItemFragment'
import styles from './AccordionItem.module.sass'
import { ContentRendererWithInlineLink } from './ContentRenderer'
import { Icon } from './Icon'
import { Wysiwyg } from './Wysiwyg'

export type AccordionItemProps = AccordionItemResult & {
	isItemOpenId: string | null
	setItemOpenId: Dispatch<SetStateAction<string | null>>
}

export const AccordionItem: FunctionComponent<AccordionItemProps> = ({
	id,
	name,
	descriptionContent,
	isItemOpenId,
	setItemOpenId,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.main}>
				<div className={styles.header}>
					<button
						type="button"
						className={styles.button}
						onClick={() => setItemOpenId(isItemOpenId === id ? null : id)}
					>
						<h3 className={styles.name}>
							<Wysiwyg source={name} />
						</h3>
						<div className={styles.mark}>
							<div className={clsx(styles.is_minus, isItemOpenId === id && styles.is_visible)}>
								<Icon name="minus" />
							</div>
							<div className={clsx(styles.is_plus, isItemOpenId !== id && styles.is_visible)}>
								<Icon name="plus" />
							</div>
						</div>
					</button>
				</div>
				<Collapsible open={isItemOpenId === id}>
					<div className={styles.body}>
						{descriptionContent && <ContentRendererWithInlineLink content={descriptionContent} />}
					</div>
				</Collapsible>
			</div>
		</div>
	)
}
