import clsx from 'clsx'
import { type FunctionComponent, useEffect, useRef, useState } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { Footer } from './Footer'
import { Header } from './Header'
import styles from './Layout.module.sass'

export type LayoutProps = Pick<PageProps, 'header' | 'footer'> & {
	isHeaderOverContent?: boolean
	children?: React.ReactNode
	pageType?: 'generic'
}

export const Layout: FunctionComponent<LayoutProps> = ({
	isHeaderOverContent = false,
	header,
	footer,
	children,
	pageType,
}) => {
	const headerRef = useRef<HTMLElement>(null)
	const [headerHeight, setHeaderHeight] = useState(103)
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		setHeaderHeight(headerRef.current?.clientHeight || 103)
	}, [headerRef.current?.clientHeight])

	return (
		<div
			className={clsx(styles.wrapper, pageType && styles[`view_${pageType}`])}
			style={{ '--Layout-header-height': `${headerHeight}px` }}
		>
			<header className={clsx(styles.header, isHeaderOverContent && styles.is_overContent)} ref={headerRef}>
				{header && <Header header={header} />}
			</header>
			<main className={styles.main}>{children}</main>

			<footer className={styles.footer}>{footer && <Footer footer={footer} />}</footer>
		</div>
	)
}
