import clsx from 'clsx'
import { FunctionComponent, useCallback, useState } from 'react'
import { VideoResult } from '../data/VideoFragment'
import { ResponsiveImage } from './ResponsiveImage'
import styles from './Video.module.sass'

export type VideoProps = VideoResult

export const Video: FunctionComponent<VideoProps> = ({ url, width, height, poster, type }) => {
	const [isPosterHidden, setPosterHidden] = useState(false)
	const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null)

	const handlePlayVideo = useCallback(() => {
		setPosterHidden(true)
		videoRef?.play()
	}, [videoRef])
	return (
		<div
			className={styles.wrapper}
			style={{
				'--Video-width': width,
				'--Video-height': height,
			}}
		>
			{poster && (
				<button
					type="button"
					aria-label={poster.alt ?? 'poster'}
					onClick={handlePlayVideo}
					className={clsx(styles.poster, isPosterHidden && styles.is_hidden)}
				>
					<ResponsiveImage
						className={styles.poster}
						src={poster.url}
						fill
						alt={poster.alt ?? ''}
						sizes="100vw, (min-width: 650px) 700px"
					/>
					<div className={styles.playButton} />
				</button>
			)}
			<video className={styles.video} controls placeholder={poster?.url} playsInline ref={setVideoRef}>
				<source src={url} type={type} />
			</video>
		</div>
	)
}
