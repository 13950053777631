import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { usePlatform } from 'react-use-platform'
import { ContentWithLinkResult } from '../data/ContentWithLinkFragment'
import { ImageResult } from '../data/ImageFragment'
import { MediumListResult } from '../data/MediumListFragment'
import { appButtonsVisibilityByPlatformType } from '../utilities/appButtonsVisibilityByPlatformType'
import { AppLink } from './AppLink'
import styles from './ContentMedia.module.sass'
import { ContentMediaGallery } from './ContentMediaGallery'
import { MediaContentText, MediaContentTitle } from './MediaContent'
import { ResponsiveImage } from './ResponsiveImage'

export type ContentMediaProps = {
	title?: string
	textContent?: ContentWithLinkResult
	media?: MediumListResult
	image?: ImageResult
	isAppsVisible?: boolean
}

export const ContentMedia: FunctionComponent<ContentMediaProps> = ({
	title,
	textContent,
	media,
	image,
	isAppsVisible,
}) => {
	const platform = usePlatform()

	return (
		<div className={styles.wrapper}>
			<div className={clsx(styles.content, isAppsVisible && styles.is_appVisible)}>
				{title && (
					<div className={styles.title}>
						<MediaContentTitle title={title} />
					</div>
				)}
				{textContent && (
					<div className={styles.text}>
						<MediaContentText textContent={textContent} />
					</div>
				)}
				{isAppsVisible && (
					<div className={styles.apps}>
						{appButtonsVisibilityByPlatformType[platform]['appStore'] && (
							<div className={styles.app}>
								<AppLink type="apple" />
							</div>
						)}
						{appButtonsVisibilityByPlatformType[platform]['googlePlay'] && (
							<div className={styles.app}>
								<AppLink type="google" />
							</div>
						)}
					</div>
				)}
			</div>
			<div className={styles.media}>
				{media?.items && (
					<div className={styles.gallery}>
						<ContentMediaGallery list={media.items} />
					</div>
				)}
				<div className={clsx(styles.image, styles.is_mobile)}>
					{image && (
						<ResponsiveImage
							src={image.url}
							width={image.width}
							height={image.height}
							alt={image.alt ?? ''}
							sizes="100vw, (min-width: 1100px) 1200px"
						/>
					)}
				</div>
			</div>
		</div>
	)
}
