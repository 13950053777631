import clsx from 'clsx'
import Link from 'next/link'
import { Dispatch, FunctionComponent, MouseEventHandler, SetStateAction, useCallback } from 'react'
import { usePlatform } from 'react-use-platform'
import { appButtonsVisibilityByPlatformType } from '../utilities/appButtonsVisibilityByPlatformType'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { useIsCurrentLink } from '../utilities/useIsCurrentLink'
import { AppLink } from './AppLink'
import { HeaderProps } from './Header'
import styles from './HeaderNavigation.module.sass'
export type HeaderNavigationProps = Pick<NonNullable<HeaderProps['header']>, 'localesByLocale'>['localesByLocale'] & {
	type: 'desktop' | 'mobile'
	setMobileMenuOpen?: Dispatch<SetStateAction<boolean>>
}

export const HeaderNavigation: FunctionComponent<HeaderNavigationProps> = ({ type, links, setMobileMenuOpen }) => {
	const handleClick: MouseEventHandler<HTMLLIElement> = useCallback(
		(event) => {
			if (setMobileMenuOpen && event.target instanceof HTMLElement && event.target.closest('a')) {
				setMobileMenuOpen(false)
			}
		},
		[setMobileMenuOpen],
	)

	const platform = usePlatform()

	const isCurrentLink = useIsCurrentLink()

	return (
		<nav className={clsx(styles.wrapper, styles[`is_${type}`])}>
			{type === 'mobile' && setMobileMenuOpen && (
				<button className={styles.close} onClick={() => setMobileMenuOpen && setMobileMenuOpen(false)}>
					<span className={styles.closeLine} />
					<span className={styles.closeLine} />
				</button>
			)}
			<ul className={styles.list}>
				{links?.items.map(
					(item) =>
						item.link && (
							<li key={item.id} className={styles.item} onClick={handleClick}>
								<Link
									className={clsx(styles.link, isCurrentLink(item.link) && styles.is_active)}
									href={contemberLinkToHref(item.link)}
									// @TODO: own component
									target={item.link.isTargetBlank ? '_blank' : undefined}
								>
									{item.link.title}
								</Link>
							</li>
						),
				)}
			</ul>
			<ul className={styles.appList}>
				{appButtonsVisibilityByPlatformType[platform]['appStore'] && (
					<li className={styles.appItem}>
						<AppLink type="apple" />
					</li>
				)}
				{appButtonsVisibilityByPlatformType[platform]['googlePlay'] && (
					<li className={styles.appItem}>
						<AppLink type="google" />
					</li>
				)}
			</ul>
		</nav>
	)
}
